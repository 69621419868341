import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlertController, LoadingController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { Router, NavigationExtras } from '@angular/router';

const API_BASE_URL = 'https://painel.adm.aquitandinhahortifruti.com/ws/';
const API_EXT = '.php';
const API_IMG_URL = 'https://img.aquitandinhahortifruti.com/';
const API_ANONYMOUS_USER = 'AN0NYM0US';
const API_ANONYMOUS_TOKEN = 'K005HMXY16LF3M72L2SRTBV3J6';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    public response: any;
    data: Observable<any>;
    isLoading = false;

    constructor(private http: HttpClient, private alertCtrl: AlertController, private loadingCtrl: LoadingController, private router: Router) { }

    //Get URL
    getUrl(serviceName) {
        return API_BASE_URL + serviceName + API_EXT;
    }

    //Get Image Url
    getImgUrl() {
        return API_IMG_URL;
    }

    getAUser() {
        return API_ANONYMOUS_USER;
    }

    getAToken() {
        return API_ANONYMOUS_TOKEN;
    }

    //Get Request
    sendGetRequest(getData, serviceName) {
        return new Promise((resolve, reject) => {
            this.data = this.http.get(API_BASE_URL + serviceName + API_EXT + '?' + getData);
            this.data.subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            )
        });
    }

    //Post Request
    sendPostRequest(postData, serviceName) {
        return new Promise((resolve, reject) => {
            this.data = this.http.post(API_BASE_URL + serviceName + API_EXT, postData);
            this.data.subscribe(
                data => {
                    resolve(data);
                },
                error => {
                    reject(error);
                }
            )
        });
    }

    //Alerts
    async presentAlert(header, message, buttons) {
        const alert = await this.alertCtrl.create({
            header: header,
            message: message,
            buttons: buttons
        });
        await alert.present();
    }

    getButtonAlert (text) {
        let btn = [
            {
                text: text,
                role: 'cancel',
                cssClass: 'alertButton',
                handler: () => {
                    //console.log('Confirm Cancel');
                }
            }
        ]
        return btn;
    }

    //Loading
    async presentLoading(message) {
        this.isLoading = true;
        return await this.loadingCtrl.create({
            message: message,
            spinner: "crescent"
        }).then(a => {
            a.present().then(() => {
                //console.log('presented');
                if (!this.isLoading) {
                    a.dismiss().then(() => console.log('Done!'));
                }
            });
        });
    }

    async dismissLoading() {
        this.isLoading = false;
        return await this.loadingCtrl.dismiss().then(() => console.log('Done!'));
    }

    loginId(user) {
        if (user == null) {
            return API_ANONYMOUS_USER;
        } else {
            return user.user_id;
        }
    }

    loginToken(user) {
        if (user == null) {
            return API_ANONYMOUS_TOKEN;
        } else {
            return user.token;
        }
    }

    isLogin(user) {
        if (user == null) {
            return false;
        } else {
            return true;
        }
    }


    //Requests to this application =======================================================

    //Search
    searchData(customer_id: any, token: any, title: string): Observable<any> {
        return this.http.get(API_BASE_URL + 'Shop' + API_EXT + '?' + 'customer_id=' + customer_id + '&token=' + token + '&search=' + title)
            .pipe(
                map(results => {
                    return results;
                })
            );
    }

    //Cart
    async showCartAlert(product, customer_id, token) {
        let peso = product.average_weight;
        peso = (peso / 1000).toFixed(3) + 'kg';
        let msg = 'Produto: <b>' + product.name + '</b><br>Unidade de medida: <b>' + product.unit + '</b>';
        if (product.unit == 'Kg') {
            msg = msg + '<br>Peso médio da Unidade: <b>' + peso + '</b>';
        }

        const alert = await this.alertCtrl.create({
            header: 'Adicionar ao carrinho',
            message: msg,
            inputs: [
                {
                    name: 'qtd',
                    type: 'number',
                    placeholder: 'Quantidade em '+product.unit
                }
            ],
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        //console.log('Confirm Cancel');
                    }
                }, {
                    text: 'Adicionar',
                    handler: (alertData) => {
                        if (alertData.qtd > 0) {
                            this.addToCart(product.id, this.convertQtd(product.unit, alertData.qtd, product.average_weight), customer_id, token);
                        } else {
                            this.presentAlert('Erro ao atualizar', 'A quantidade inserida é inválida.', ['Ok']);
                        }
                    }
                }
            ]
        });

        await alert.present();
    }

    convertQtd (unit, qtd, average_weight) {
        let finalQtd;
        if (unit == 'Kg') {
            let gramas = qtd*1000;
            if(gramas<average_weight){
                finalQtd = average_weight/1000;
            }else{
                let media = gramas/average_weight;
                if(media % 1 != 0){
                    media = Math.floor(gramas/average_weight);
                    finalQtd = (((media*average_weight)+parseFloat(average_weight))/1000).toFixed(2);
                }else{
                    finalQtd = (gramas/1000).toFixed(2);
                }
            }
        } else {
            finalQtd = Math.ceil(qtd);
        }
        return finalQtd;
    }

    async cartAlert(product, customer_id, token) {
        if (customer_id == API_ANONYMOUS_USER) {
            this.alertAskLogin();
        } else {
            this.showCartAlert(product, customer_id, token);
        }
    }

    async retryCartAlert(product_id, qtd, customer_id, token) {
        const alert = await this.alertCtrl.create({
            header: 'Erro ao adicionar',
            message: 'Houve um erro ao tentar adicionar o produto ao carrinho, gostaria de tentar novamente?',
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        //console.log('Confirm Cancel');
                    }
                }, {
                    text: 'Sim',
                    handler: () => {
                        this.addToCart(product_id, qtd, customer_id, token);
                    }
                }
            ]
        });

        await alert.present();
    }

    addToCart(product_id, qtd, customer_id, token) {
        this.presentLoading('Processando...');
        let postData = new FormData();
        postData.append('product_id', product_id);
        postData.append('qtd', qtd);
        postData.append('customer_id', customer_id);
        postData.append('token', token);
        this.sendPostRequest(postData, 'Cart').then(data => {
            this.response = data;
            //console.log(this.response);
            if (this.response.status == true) {
                this.dismissLoading();
                this.buyOrCart();
            } else {
                this.dismissLoading();
                this.retryCartAlert(product_id, qtd, customer_id, token);
            }
        })
    }

    async buyOrCart() {
        const alert = await this.alertCtrl.create({
            header: 'Item Adicionado',
            message: 'Seu item foi adicionado ao carrinho, deseja continuar comprando ou ir para o carrinho?',
            buttons: [
                {
                    text: 'Continuar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        //console.log('Confirm Cancel');
                    }
                }, {
                    text: 'Carrinho',
                    handler: () => {
                        this.router.navigate(['members/cart']);
                    }
                }
            ]
        });

        await alert.present();
    }

    openProduct(product) {
        let navigationExtras: NavigationExtras = {
            queryParams: product
        }
        this.router.navigate(['product'], navigationExtras);
    }

    async alertAskLogin() {

        const alert = await this.alertCtrl.create({
            header: 'Necessita login',
            message: 'Para adicionar quaisquer produtos ao carrinho é necessário estar logado em sua conta.',
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        //console.log('Confirm Cancel');
                    }
                }, {
                    text: 'Login',
                    handler: () => {
                        this.router.navigate(['public/login']);
                    }
                }
            ]
        });

        await alert.present();
    }

}