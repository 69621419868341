import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
    {
        path: 'members',
        loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'signup',
        loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule)
    },
    {
        path: '',
        loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
    },
    {
        path: 'terms',
        loadChildren: () => import('./terms/terms.module').then(m => m.TermsPageModule)
    },
    {
        path: 'product',
        loadChildren: () => import('./product/product.module').then(m => m.ProductPageModule)
    },
    {
        path: 'info',
        loadChildren: () => import('./info/info.module').then(m => m.InfoPageModule)
    },
    {
        path: 'search',
        loadChildren: () => import('./search/search.module').then(m => m.SearchPageModule)
    },
    {
        path: 'public',
        loadChildren: () => import('./public/public.module').then(m => m.PublicPageModule)
    },
    {
        path: 'search-cnpj',
        loadChildren: () => import('./search-cnpj/search-cnpj.module').then( m => m.SearchCnpjPageModule)
    }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
