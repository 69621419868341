import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { Platform, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, from, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ApiService } from './api.service';

const TOKEN_KEY = 'token';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public user: Observable<any>;
    private userData = new BehaviorSubject(null);
    public response: any;
    public validToken: any;

    constructor(private storage: Storage, private http: HttpClient, private plt: Platform, private router: Router, private api: ApiService, private alertCtrl: AlertController) {
        this.loadStoredToken();
    }

    loadStoredToken() {
        let platformObs = from(this.plt.ready());
        this.user = platformObs.pipe(
            switchMap(() => {
                return from(this.storage.get(TOKEN_KEY))
            }),
            map(token => {
                //console.log('Token storage: ', token.token);
                if (token) {
                    this.userData.next(token);
                    return true;
                } else {
                    return null;
                }
            })
        )
    }

    tryLogin(email, password) {
        this.api.presentLoading('Realizando Login');
        let postData = new FormData();
        postData.append('email', email);
        postData.append('password', password);

        return this.http.post(this.api.getUrl('Login'), postData).pipe(
            take(1),
            map(res => {
                return res;
            }),
            switchMap(token => {
                this.userData.next(token);
                let storageObs = from(this.storage.set(TOKEN_KEY, token));
                return storageObs;
            }),
        );
    }

    getUser() {
        return this.userData.getValue();
    }

    logout() {
        this.storage.remove(TOKEN_KEY).then(() => {
            this.router.navigateByUrl('public/login');
            this.userData.next(null);
        })
    }

    isLogged() {
        this.storage.get('token').then((data) =>{
            this.api.sendGetRequest('token='+data.token, 'Login').then((result) => {
                this.validToken = result;
                //console.log(this.validToken.status);
                if(this.validToken.status==true){
                    this.router.navigate(['members/']);
                }else{
                    this.logout();
                }
            });
        })
    }

    stillLogged() {
        this.storage.get('token').then((data) =>{
            this.api.sendGetRequest('token='+data.token, 'Login').then((result) => {
                this.validToken = result;
                //console.log(this.validToken.status);
                if(this.validToken.status!=true){
                    this.logout();
                }
            });
        })
    }

}
